import "./components/gdpr";
import "./components/tailwind";
import "./components/gsap";
import "./components/lenis";
import "./components/confetti";
import "./components/mouseFollower";
import "./components/eyeFollower";
import "./components/alpine";
import "./components/lazyLoading";

// allow transition after page load
window.addEventListener("load", () => {
    document.querySelectorAll(".prevent-load-transition").forEach((el) => {
        el.classList.remove("prevent-load-transition");
    });
});
