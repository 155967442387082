// Import Lenis
import Lenis from "@studio-freight/lenis";

const isTouchDevice = window.matchMedia("(pointer: coarse)").matches;

if (document.body.dataset.editmode !== "true" && !isTouchDevice) {
    // Initialize Lenis
    const lenis = new Lenis({
        duration: 0.5,
        easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
    });

    lenis.on("scroll", ScrollTrigger.update);
    lenis.on("scroll", (e) => {window.dispatchEvent(new CustomEvent('lenis-scroll', {detail: e}))});

    gsap.ticker.add((time) => {
        lenis.raf(time * 1000);
    });
}
