class EyeFollower {
    MAX_RADIUS = 3;

    constructor(container) {
        this.container = container;
    }

    onMove(event) {
        if (!this.container) return;

        const rect = this.container.getBoundingClientRect();
        const centerX = rect.width / 2;
        const centerY = rect.height / 2;

        const mouseX = event.clientX - rect.left;
        const mouseY = event.clientY - rect.top;

        // Calculate the distance from the center of the button to the mouse position
        const diffX = mouseX - centerX;
        const diffY = mouseY - centerY;
        const distance = Math.sqrt(diffX * diffX + diffY * diffY);

        // If the distance is within the maxRadius, use it. Otherwise, restrict the div to the edge of the maxRadius circle.
        const theta = Math.atan2(diffY, diffX);
        const radius = Math.min(distance, this.MAX_RADIUS);

        // Calculate the new position based on the angle and radius
        const x = radius * Math.cos(theta);
        const y = radius * Math.sin(theta);

        const eyeBall = this.container.querySelector(".eye-ball");

        gsap.to(eyeBall, {
            x: x,
            y: y,
            duration: 0.3,
            ease: "power2.out",
        });
    }

    onLeave() {
        if (!this.container) return;
        const eyeBall = this.container.querySelector(".eye-ball");

        gsap.to(eyeBall, {
            x: 0,
            y: 0,
            duration: 0.3,
            ease: "power2.out",
        });
    }
}

window.EyeFollower = EyeFollower;
