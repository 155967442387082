import lozad from 'lozad'

window.lozad = lozad;

window.observer = window.lozad('.lozad', {
    threshold: 0.1,
    rootMargin: '150% 150% 150% 150%',
    enableAutoReload: true,
    load: function (el) {
        // Simple img-tag
        if(el.hasAttribute('lozad-simple-img')){
            el.setAttribute('src', el.getAttribute('data-src'))
            el.removeAttribute('data-src')
            return;
        }
        
        // Video
        if(el.hasAttribute('lozad-video')){
            el.querySelectorAll('[data-src]').forEach((child) => {
                child.setAttribute('src', child.getAttribute('data-src'))
                child.removeAttribute('data-src')
            })
            const event = new Event("loaded");
            el.dispatchEvent(event);
            return;
        }
        
        // Lazy loaded component
        if(el.hasAttribute('lozad-component')){
            const event = new Event("loaded");
            el.dispatchEvent(event);
            return;
        }
        
        // Picture (thumbnails)
        const picture = el.closest('picture');
        if(!picture.classList.contains('avoid-lazy-loading')){
            picture.querySelectorAll('[data-srcset]').forEach((child) => {
                child.setAttribute('srcset', child.getAttribute('data-srcset'))
                child.removeAttribute('data-srcset')
            })
        }

    },
    loaded: function (el) {
        const lazyLoadWrap = el.closest('.lazy-load-wrap');
        const placeholder = lazyLoadWrap ? lazyLoadWrap.querySelector('.lazy-load-placeholder') : null

        if (lazyLoadWrap) {
            if (lazyLoadWrap.getAttribute('data-lazy-remove')) lazyLoadWrap.classList.remove(...lazyLoadWrap.getAttribute('data-lazy-remove').split(' '))
            if (lazyLoadWrap.getAttribute('data-lazy-add')) lazyLoadWrap.classList.add(...lazyLoadWrap.getAttribute('data-lazy-add').split(' '))
        }

        if (placeholder) {
            if (placeholder.getAttribute('data-lazy-remove')) placeholder.classList.remove(...placeholder.getAttribute('data-lazy-remove').split(' '))

            if (placeholder.getAttribute('data-lazy-add')) {
                placeholder.classList.add(...placeholder.getAttribute('data-lazy-add').split(' '))
            } else {
                if (placeholder.getAttribute('class').indexOf('transition-') === -1) placeholder.classList.add('transition-all')
                if (placeholder.getAttribute('class').indexOf('duration-') === -1) placeholder.classList.add('duration-500')
                if (placeholder.getAttribute('class').indexOf('opacity-') === -1) placeholder.classList.add('opacity-0')
                placeholder.classList.add('invisible')
            }
        }
    }
});

window.observer.observe();
