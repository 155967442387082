// Alpine
import Alpine from "alpinejs";
import persist from "@alpinejs/persist";

// Initialize alpine
Alpine.plugin(persist);

// Dark mode store
Alpine.store('darkMode', {
    on: true,

    init() {
        this.on = JSON.parse(localStorage.getItem('darkMode')) ?? true
        document.documentElement.classList.remove('dark');
    },
 
    toggle() {
        this.on = !this.on
        localStorage.setItem('darkMode', this.on)
    }
})

// Plugins
import { Loader } from "@googlemaps/js-api-loader";
import Swiper from "swiper/bundle";
import "swiper/css/bundle";
import Plyr from "plyr";

// Add to window
window.Alpine = Alpine;
window.Swiper = Swiper;
window.Plyr = Plyr;
window.GoogleMapsLoader = Loader;
window.Alpine.start();
