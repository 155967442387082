import GdprConsent from "@AccWebsiteBundle/GdprConsent/GdprConsent.js";

const gdprNode = document.querySelector('[data-component="GdprConsent"]');

if(gdprNode){
    let props = gdprNode.getAttribute("data-props");
    if (props) props = JSON.parse(props);
    
    new GdprConsent(gdprNode, props);
}
