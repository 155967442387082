import MouseFollower from "mouse-follower";

if (document.body.dataset.editmode !== "true") {
    MouseFollower.registerGSAP(window.gsap);

    window.cursor = new MouseFollower({
        skewing: 0,
        skewingText: 0,
    });

    const isTouchDevice = window.matchMedia("(pointer: coarse)").matches;

    if (isTouchDevice) {
        const cursor = document.querySelector(".mf-cursor");
        cursor.style.display = "none";
    }
}
